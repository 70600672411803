import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <p>There are 11 standard character classes defined in the SRD.
They are:</p>
    <div style={{
      "marginLeft": "30px"
    }}>
      <a style={{
        "color": "black"
      }} href="barbarian.html"><b>Barbarian</b></a>,
      <a style={{
        "color": "black"
      }} href="bard.html"><b>Bard</b></a>,
      <a style={{
        "color": "black"
      }} href="cleric.html"><b>Cleric</b></a>,
      <a style={{
        "color": "black"
      }} href="druid.html"><b>Druid</b></a>,
      <a style={{
        "color": "black"
      }} href="fighter.html"><b>Fighter</b></a>,
      <a style={{
        "color": "black"
      }} href="monk.html"><b>Monk</b></a>,
      <a style={{
        "color": "black"
      }} href="paladin.html"><b>Paladin</b></a>,
      <a style={{
        "color": "black"
      }} href="ranger.html"><b>Ranger</b></a>,
      <a style={{
        "color": "black"
      }} href="rogue.html"><b>Rogue</b></a>,
      <a style={{
        "color": "black"
      }} href="sorcerer.html"><b>Sorcerer</b></a>,
      <a style={{
        "color": "black"
      }} href="wizard.html"><b>Wizard</b></a>.
    </div>
    <h3><a id="multiclass-characters"></a>MULTICLASS CHARACTERS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial">A character may add new classes      as he or she
progresses in level, thus becoming a multiclass character. The class
abilities from a character�s different classes combine to
determine a
multiclass character�s overall abilities. Multiclassing improves
a
character�s versatility at the expense of focus.</p>
    <h5><a id="multiclass-class-and-level-features"></a>CLASS AND LEVEL FEATURES</h5>
    <p className="initial">As a general rule, the abilities of a
multiclass character are the sum of the abilities of each of the
character�s classes.</p>
    <p><a id="multiclass-level"></a><b>Level</b>: �Character level� is a
character�s total number
of levels. It is used to determine when feats and ability score boosts
are gained.</p>
    <p>�Class level� is a character�s level in a
particular class.
For a character whose levels are all in the same class, character level
and class level are the same.</p>
    <p><a id="multiclass-hit-points"></a><b>Hit Points</b>: A character gains hit points from each
class as his or her class level increases, adding the new hit points to
the previous total.</p>
    <p><a id="multiclass-base-attack-bonus"></a><b>Base Attack Bonus</b>: Add the base attack bonuses acquired
for each class to get the character�s base attack bonus. A
resulting
value of +6 or higher provides the character with multiple attacks.</p>
    <p><a id="multiclass-saving-throws"></a><b>Saving Throws</b>: Add the base save bonuses for each class
together.</p>
    <p><a id="multiclass-skills"></a><b>Skills</b>: If a skill is a class skill for any of a
multiclass character�s classes, then character level determines a
skill�s maximum rank. (The maximum rank for a class skill is 3 +
character level.)</p>
    <p>If a skill is not a class skill for any of a multiclass
character�s classes, the maximum rank for that skill is one-half
the
maximum for a class skill.</p>
    <p><a id="multiclass-class-features"></a><b>Class Features</b>: A multiclass character gets all the
class features of all his or her classes but must also suffer the
consequences of the special restrictions of all his or her classes.
(Exception: A character who acquires the barbarian class does not
become illiterate.)</p>
    <p>In the special case of turning undead, both clerics and
experienced paladins have the same ability. If the character�s
paladin
level is 4th or higher, her effective turning level is her cleric level
plus her paladin level minus 3.</p>
    <p>In the special case of uncanny dodge, both experienced
barbarians and experienced rogues have the same ability. When a
barbarian/rogue would gain uncanny dodge a second time (for her second
class), she instead gains improved uncanny dodge, if she does not
already have it. Her barbarian and rogue levels stack to determine the
rogue level an attacker needs to flank her.</p>
    <p>In the special case of obtaining a familiar, both wizards and
sorcerers have the same ability. A sorcerer/wizard stacks his sorcerer
and wizard levels to determine the familiar�s natural armor,
Intelligence score, and special abilities.</p>
    <p><a id="multiclass-feats"></a><b>Feats</b>: A multiclass character gains feats based on
character levels, regardless of individual class level.</p>
    <p><a id="multiclass-ability-increase"></a><b>Ability Increases</b>: A multiclass character gains ability
score increases based on character level, regardless of individual
class level.</p>
    <p><a id="multiclass-spells"></a><b>Spells</b>: The character gains spells from all of his or
her spellcasting classes and keeps a separate spell list for each
class. If a spell�s effect is based on the class level of the
caster,
the player must keep track of which class�s spell list the
character is
casting the spell from.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      